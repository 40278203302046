import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { TimeoutError } from 'rxjs/internal/operators/timeout';
import { BLANK, QUESTION_MARK } from '@shared/constants/char.constant';
import { AuthenticationService } from '@app/services/authentication.service';
//import { AuthenticationService } from '@app/auth';

const log = new Logger('ErrorHandlerInterceptor');

const TIMEOUT_ERROR_NAME = 'TimeoutError';
const TIMEOUT_ERROR_CODE = '408';
const LOGIN_URLS = ['/login', '/login-cgi'];
const TIMEOUT_HEADER = 'Timeout';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService // private toastr: ToastrService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutValue = request.headers.get(TIMEOUT_HEADER) || 20000;
    const timeoutValueNumeric = Number(timeoutValue);
    const handledTimeoutRequest = request.headers.has(TIMEOUT_HEADER)
      ? request.clone({
          headers: request.headers.delete(TIMEOUT_HEADER),
        })
      : request;

    return next.handle(handledTimeoutRequest).pipe(
      timeout(timeoutValueNumeric),
      catchError((error) => this.errorHandler(error, request))
    );
  }

  // Customize the default error handler here if needed
  // private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
  //   if (!environment.production) {
  //     // Do something with the error
  //     log.error('Request error', response);
  //   }
  //   throw response;
  // }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any> | TimeoutError, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    // if (!environment.production) {
    //   // Do something with the error
    //   log.error('Request error', response);
    // }
    console.log('error handle', response);
    if (response instanceof HttpErrorResponse) {
      switch (response.status) {
        case 0:
          this.handleNetworkError(response);
          break;
        case 504:
          this.handleGatewayTimeoutError(response);
          break;
        case 400:
          this.handleBadRequestError(response);
          break;
        case 401:
          this.handleUnauthorizedRequestError(response);
          break;
        case 403:
          this.router.navigate(['/request-access']);
          break;
        default:
          // Regardless of what type of HttpError happening to /token, it should be handled
          this.handleTokenProcurementError(response);
          throw response;
      }
    }
    if ((response as TimeoutError).name === TIMEOUT_ERROR_NAME) {
      if (environment.production) {
        log.error('Request error', response);
      }

      this.handleTokenProcurementError(response as any as HttpErrorResponse, request.url, TIMEOUT_ERROR_CODE);
    }

    throw response;
  }

  private handleNetworkError(err: HttpErrorResponse): Observable<any> {
    this.router.navigate([`/error`], { queryParams: { errorCode: err.status } });
    return of(err.message);
  }

  private handleGatewayTimeoutError(err: HttpErrorResponse): Observable<any> {
    // navigate /delete cookies or whatever
    this.router.navigate([`/error`], { queryParams: { returnUrl: err.url } });
    // if you've caught / handled the error, you don't want to rethrow it
    // unless you also want downstream consumers to have to handle it as well.
    return of(err.message);
  }

  private handleInterServerError(err: HttpErrorResponse): Observable<any> {
    console.log(err);
    // this.toastr.error(err.error, 'Feil!');
    return of(err.message);
  }

  private handleConflictError(err: HttpErrorResponse): Observable<any> {
    // this.toastr.error('Det er noe feil. Prøv senere eller kontakt administrater', 'Feil!');
    return of(err.message);
  }

  /**
   * Handle token procurement error by logging the current user out and navigate to the error page
   * with the exception of when the user is at the login pages
   *
   * @param err error object
   * @param errorUrl the URL relevant to the error (either from the request or error object)
   * @param errorCode the HttpError code
   */
  private handleTokenProcurementError(
    err: HttpErrorResponse,
    errorUrl: string = err.url || BLANK,
    errorCode: string = `${err.status}` || null
  ): Observable<string> {
    // if (errorUrl.includes('/refresh')) {
    //   const currentPath = this.router.url.split(QUESTION_MARK)[0];
    //   if (LOGIN_URLS.some((path) => path === currentPath)) {
    //     return of(err.message);
    //   } else {
    //     this.authenticationService
    //       .logout()
    //       .pipe(take(1))
    //       .subscribe(() => {
    //         this.router.navigate(['/error'], { queryParams: { errorCode } });
    //       });
    //   }
    // }
    return of(err.message);
  }
  private handleBadRequestError(err: HttpErrorResponse): Observable<string> {
    // handle refresh token error
    // if (err.url.includes('/refresh')) {
    //   const currentPath = this.router.url.split(QUESTION_MARK)[0];
    //   if (LOGIN_URLS.some((path) => path === currentPath)) {
    //     return of(err.message);
    //   } else {
    //     this.authenticationService
    //       .logout()
    //       .pipe(take(1))
    //       .subscribe(() => {
    //         this.router.navigate(['/login'], { replaceUrl: true });
    //       });
    //   }
    // }
    return of(err.message);
  }

  private handleUnauthorizedRequestError(err: HttpErrorResponse): Observable<string> {
    this.authenticationService.logout();
    //this.router.navigate(['/login']);
    // this.authenticationService.logout().subscribe(() => {
    // var errorCode = err?.status || '401';
    // this.router.navigate(['/error'], { queryParams: { errorCode }, replaceUrl: true });
    // });
    return of(err.message);
  }
  /* private handleBadRequestError(err: HttpErrorResponse): Observable<any> {
    // handle refresh token error
    if (err.url.includes('/token')) {
      this.authenticationService.logout().subscribe(() => {
        this.router.navigate(['/login'], { replaceUrl: true });
      });
    }
    // this.toastr.error('Det er noe feil. Prøv senere eller kontakt administrater', 'Feil!');
    return of(err.message);
  } */
}
