// import { BrowserModule } from '@angular/platform-browser';
// import { ErrorHandler, NgModule } from '@angular/core';
// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// import { AppRoutingModule } from './app-routing.module';
// import { AppComponent } from './app.component';
// import { ApiService } from './services/api.service';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AuthenticationService } from './services/authentication.service';
// import '@angular/common/locales/global/is';
// import { MessageService } from 'primeng/api';
// import { ConfirmationService } from 'primeng/api';
// import { SidebarModule } from 'primeng/sidebar';
// import { DatePipe } from '@angular/common';
// import { FileSaverModule } from 'ngx-filesaver';
// import { FileUploadModule } from 'primeng/fileupload';
// import { NgxPermissionsModule } from 'ngx-permissions';
// import { CoreModule } from './@core';
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { environment } from '@env/environment';
// import { OverlayPanelModule } from 'primeng/overlaypanel';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CoreModule } from './@core';
import { SidebarModule } from 'primeng/sidebar';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './services/authentication.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe, registerLocaleData } from '@angular/common';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import localeIS from '@angular/common/locales/is';
registerLocaleData(localeIS);

@NgModule({
  imports: [
    OverlayPanelModule,
    BrowserModule,
    BrowserAnimationsModule,
    //ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    CoreModule,
    SidebarModule,
    //FileSaverModule,
    //FileUploadModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule.forRoot({
      config: {
        authority: 'https://lsretailb2c.b2clogin.com/lsretailb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/v2.0',
        authWellknownEndpointUrl:
          'https://lsretailb2c.b2clogin.com/lsretailb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/v2.0/.well-known/openid-configuration',
        redirectUrl: `${window.location.origin}/auth-callback`,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'cdf872ee-3bce-46e6-ab86-5906576a9318',
        scope:
          'openid offline_access profile https://lsretailb2c.onmicrosoft.com/cfae354a-5d07-4eb9-a1b9-79a013e41275/api',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        issValidationOff: false, // this needs to be true if using a common endpoint in Azure
        autoUserInfo: false,
        logLevel: LogLevel.Debug,
        customParamsAuthRequest: {
          prompt: 'select_account', // login, consent
        },
      },
    }),
  ],
  // providers: [
  //   { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  //   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  //   {
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: NoCacheHeadersInterceptor,
  //     multi: true,
  //   },
  //   { provide: ErrorHandler, useClass: GlobalErrorHandler },
  //   ApiService,
  //   AuthenticationService,
  //   MessageService,
  //   ConfirmationService,
  //   DatePipe,
  // ],
  declarations: [AppComponent],
  providers: [
    ApiService,
    AuthenticationService,
    MessageService,
    ConfirmationService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'is-IS' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // constructor(
  //   private readonly router: Router,
  // ) {
  //   router.events
  //     .subscribe(console.log)
  // }
}
