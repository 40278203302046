//import { webConfig } from '../../config';
//import { saveAs } from 'file-saver-es';

import { isEqual } from 'lodash';
import { Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AsyncValidatorFn, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
//import { AccountInfo, SchoolType, SearchResponseModel } from '@app/models';
import { BLANK, ZERO } from '@app/@shared/constants/char.constant';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ValidatorFn } from '@angular/forms';

export enum DateFormat {
  XLS_DOWNLOAD_SUFFIX = 'yyyy.MM.dd_HH.mm',
  DDMMYYYY = 'dd.MM.yyyy',
  DDMMYY = 'dd.MM.yy',
  DDMMYYYY_HHMMSS = 'dd.MM.yyyy HH:mm:ss',
  DDMMYYYY_HHMM = 'dd.MM.yyyy HH:mm',
  DDMM_HHMM = 'dd.MM HH:mm',
  DDMM_HMM = 'dd.MM H:mm',
  HHMM = 'HH:mm',
  HH = 'HH',
  // YYYY_MM_DDTHH_MM_SS = 'yyyy-MM-ddTHH:mm:ss'
  YYYY_MM_DDTHH_MM_SS = 'yyyy-MM-dd',
}

export enum IntlDateTimeFormatOptions {
  LONG = 'long',
  SHORT = 'short',
  NARROW = 'narrow',
  NUMERIC = 'numeric',
  TWO_DIGIT = '2-digit',
}

export enum Locale {
  EN_NO = 'en-NO',
  NB_NO = 'nb-NO',
}

export interface TimeDifference {
  hour: number;
  minute: number;
}

interface DivDeclaration {
  content: string;
  extraClass?: string;
}

const DOT = '●';
const NORWEGIAN_LETTERS: string[] = ['aa', 'å', 'ø', 'æ'];
const NORWEGIAN_LOCALE = 'nb';
const ONE_MINUTE_IN_MILISECONDS = 60 * 1000;
const UTC_CHARACTER = 'Z';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private translateService: TranslateService, private router: Router) {}

  /**
   * Re-format datetime to Norway standard using custom format
   * @param dateTime
   */
  formatNorwayDateTimeCustomFormat(dateTime: string | Date, format: DateFormat) {
    try {
      return new DatePipe(Locale.EN_NO).transform(dateTime, format);
    } catch {
      return '';
    }
  }

  /**
   * Re-format datetime to Norway standard - only Date
   * @param dateTime
   */
  formatNorwayDate(dateTime: string | Date) {
    return this.formatNorwayDateTimeCustomFormat(dateTime, DateFormat.DDMMYYYY);
  }

  /**
   * Format Norway date to standard date - only Date
   * @param dateTime
   */
  formatNorwayDateToStandard(dateTime: string) {
    try {
      if (dateTime.length == 0) {
        return dateTime;
      } else if (dateTime.includes('-')) {
        var d2 = new Date(dateTime);
        return d2.toLocaleDateString(Locale.EN_NO);
      } else {
        let data = dateTime.split('.');
        var d = new Date(data[2] + '-' + data[1] + '-' + data[0]);
        return d.toLocaleDateString(Locale.EN_NO);
      }
    } catch {
      return '';
    }
  }

  /**
   * Get Date object from string in 'dd.mm.yyyy' format
   *
   * @param dateString
   */
  parseDate(dateString: string): Date {
    const isValidFormat = new RegExp(/^\d{2}\.\d{2}\.\d{4}$/).test(dateString);
    if (!isValidFormat) {
      return null;
    }

    const dateParts = dateString.split(DOT);
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];
    if (dateParts.length === 3) {
      const reformatDateString = `${year}-${month}-${day}T00:00:00`;
      const date = new Date(Date.parse(reformatDateString));
      if (date.getDate() === parseInt(day, 10)) {
        return date;
      }
    }

    return null;
  }

  /**
   * Re-format datetime to Norway standard - Date and Time
   * @param dateTime
   */
  formatNorwayDateTime(dateTime: string | Date) {
    return this.formatNorwayDateTimeCustomFormat(dateTime, DateFormat.DDMMYYYY_HHMMSS);
  }

  norwayDateValidator(invalidReason: string): ValidatorFn {
    return (c: FormControl) => {
      let isValid = true;
      if (c.value) {
        let data = c.value.split('.');

        if (data.length < 3) {
          if (invalidReason == 'invalidFromDate') {
            return { invalidFromDate: '' };
          } else {
            return { invalidToDate: '' };
          }
        }

        let d = new Date(data[2] + '-' + data[1] + '-' + data[0]);

        if (data[2].toString().length != 4) {
          if (invalidReason == 'invalidFromDate') {
            return { invalidFromDate: '' };
          } else {
            return { invalidToDate: '' };
          }
        }

        isValid =
          d && d.getMonth() + 1 == data[1] && d.getDate() == Number(data[0]) && d.getFullYear() == Number(data[2]);
      }
      if (isValid) {
        return null;
      } else {
        if (invalidReason == 'invalidFromDate') {
          return { invalidFromDate: '' };
        } else {
          return { invalidToDate: '' };
        }
      }
    };
  }

  /**
   * Re-format datetime to Norway standard - Date and Time
   * @param dateTime
   */
  formatNorwayDateTimeNoSecond(dateTime: string | Date) {
    return this.formatNorwayDateTimeCustomFormat(dateTime, DateFormat.DDMMYYYY_HHMM);
  }

  /**
   * Re-format datetime to Norway standard - Time with no second
   * @param dateTime
   */
  formatNorwayTimeNoSecond(dateTime: string | Date) {
    return this.formatNorwayDateTimeCustomFormat(dateTime, DateFormat.HHMM);
  }

  /**
   * Re-format datetime to Norway standard - just show hours
   * @param dateTime
   */
  formatNorwayTimeWithJustHour(dateTime: string | Date) {
    try {
      return this.formatNorwayDateTimeCustomFormat(dateTime, DateFormat.HH);
    } catch {
      return '';
    }
  }

  /**
   * Convert ISO date string to local date string based on input locale and date format options
   * default locale is norwegian
   * default options are [long weekday] [numeric date] [short month] [numeric year]
   * [2-digit hour] [2-digit minute] [2-digit second]
   *
   * @param isoString input date string in ISO format
   * @param locale locale string
   * @param options date format options based on Intl.DateTimeFormat
   * @returns local date string
   */
  formatISODateStringToLocale(
    isoString: string,
    locale: Locale = Locale.NB_NO,
    options?: Intl.DateTimeFormatOptions
  ): string {
    let dateFormatOptions: Intl.DateTimeFormatOptions = {
      weekday: IntlDateTimeFormatOptions.LONG,
      day: IntlDateTimeFormatOptions.NUMERIC,
      month: IntlDateTimeFormatOptions.SHORT,
      year: IntlDateTimeFormatOptions.NUMERIC,
      hour: IntlDateTimeFormatOptions.TWO_DIGIT,
      minute: IntlDateTimeFormatOptions.TWO_DIGIT,
      second: IntlDateTimeFormatOptions.TWO_DIGIT,
    };
    dateFormatOptions = { ...dateFormatOptions, ...options };
    try {
      return new Date(isoString).toLocaleDateString(locale, dateFormatOptions);
    } catch {
      return '';
    }
  }

  /**
   * Return an ISOstring of the date represented by local time
   *
   * @param date local date object
   */
  getDateWithoutTimezoneOffset(date: Date): string {
    const tzoffset = date.getTimezoneOffset() * ONE_MINUTE_IN_MILISECONDS;
    return new Date(date.getTime() - tzoffset).toISOString();
  }

  /**
   * Return an ISOstring of the date represented by local time
   * And no seconds/miliseconds
   * @param date local date object
   */
  getDateWithoutTimezoneOffsetAndNoSeconds(date: Date): string {
    const tzoffset = date.getTimezoneOffset() * ONE_MINUTE_IN_MILISECONDS;
    const _date = new Date(date.getTime() - tzoffset);
    _date.setSeconds(0, 0);
    return _date.toISOString();
  }

  /**
   * Return a string date without UTC character
   *
   * @param date date string in UTC format
   */
  getDateFormatWithoutUTC(date: string): string {
    return date.replace(UTC_CHARACTER, BLANK);
  }

  /**
   * Check whether the two date are of the same date (regardless of time value)
   *
   * @param date1 first date (ISO Date-time format sting or Date Object)
   * @param date2 second date (ISO Date-time format sting or Date Object)
   */
  isSameDate(date1: string | Date, date2: string | Date): boolean {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    return (
      firstDate.getUTCDate() === secondDate.getUTCDate() &&
      firstDate.getUTCMonth() === secondDate.getUTCMonth() &&
      firstDate.getUTCFullYear() === secondDate.getUTCFullYear()
    );
  }

  /**
   * Check whether a date string or Date object is today or not
   *
   * @param date date string based on ISO Date-time format or Date Object that should be checked
   * @returns boolean
   */
  isToday(date: string | Date): boolean {
    return this.isSameDate(date, new Date());
  }

  /**
   * Check whether the period is active based on start date and stop date
   *
   * @param startDate
   * @param stopDate
   */
  isActivePeriod(startDate: string, stopDate: string): boolean {
    const now = new Date();
    return new Date(startDate) <= now && now < new Date(stopDate);
  }

  /**
   * Get the milisecond difference between the two timestamps
   *
   * @param timeBefore the time that is ahead
   * @param timeAfter the time that is after
   */
  getTimeDiffInMiliseconds(timeBefore: string | Date, timeAfter: string | Date): number {
    return new Date(timeAfter).getTime() - new Date(timeBefore).getTime();
  }

  /**
   * Get the time difference in combination of hours and minutes between the two timestamps
   *
   * @param timeBefore the time that is ahead
   * @param timeAfter the time that is after
   */
  getTimeDifference(timeBefore: string | Date, timeAfter: string | Date): TimeDifference {
    const offsetInMinutes: number = Math.floor(
      this.getTimeDiffInMiliseconds(timeBefore, timeAfter) / ONE_MINUTE_IN_MILISECONDS
    );
    const minute = offsetInMinutes % 60;
    const hour = Math.floor((offsetInMinutes - minute) / 60);
    return { hour, minute };
  }

  /**
   * Similar to getTimeDifference but no negative value possible even if the two parameters swap place
   *
   * @param time1 the first timestamp
   * @param time2 the second timestamp
   */
  getAbsoluteTimeDifference(time1: string | Date, time2: string | Date): TimeDifference {
    const offsetInMinutes: number = Math.abs(
      Math.floor(this.getTimeDiffInMiliseconds(time1, time2) / ONE_MINUTE_IN_MILISECONDS)
    );
    const minute = offsetInMinutes % 60;
    const hour = Math.floor((offsetInMinutes - minute) / 60);
    return { hour, minute };
  }

  /**
   * Get the time difference string in combination of hours and minutes between the two timestamps
   *
   * @param timeBefore the time that is ahead
   * @param timeAfter the time that is after
   */
  getTimeDifferenceString(timeBefore: string | Date, timeAfter: string | Date): string {
    const { hour, minute } = this.getTimeDifference(timeBefore, timeAfter);
    return `${hour}:${minute < 10 ? '0' + minute : minute}`;
  }

  /**
   * Create a fake password form control to override the origin one to show the last character of input value
   *
   * @param formBuilder Form Builder service to make an instant of Form Control
   * @param originPasswordFormControl The origin password field needed to be overrided
   */
  createCustomViewPasswordFormControl(formBuilder: FormBuilder, originPasswordFormControl: FormControl): FormControl {
    const customPasswordField = formBuilder.control(BLANK, originPasswordFormControl.validator);
    customPasswordField.valueChanges.subscribe((value: string) => {
      if (originPasswordFormControl && value.length !== originPasswordFormControl.value.length) {
        let newValue = BLANK;
        let encodedString = BLANK;
        if (value.length > originPasswordFormControl.value.length) {
          // handle when adding text
          newValue = originPasswordFormControl.value + value.slice(originPasswordFormControl.value.length);
          encodedString =
            DOT.repeat(newValue.length > 1 ? newValue.length - 1 : 0) + newValue.slice(newValue.length - 1);
        } else {
          // handle when deleting text
          newValue = originPasswordFormControl.value.slice(0, value.length);
          encodedString = DOT.repeat(newValue.length);
        }
        if (!originPasswordFormControl.dirty) {
          originPasswordFormControl.markAsDirty();
        }
        originPasswordFormControl.setValue(newValue);
        customPasswordField.setValue(encodedString);
      }
    });
    originPasswordFormControl.setValidators(null);
    return customPasswordField;
  }

  /**
   * Manually triggle validation functions for all form fields
   *
   * @param formGroup
   */
  updateAllFormFieldsValidation(formGroup: FormGroup | FormArray) {
    if (formGroup && formGroup.controls) {
      Object.keys(formGroup.controls).forEach((field: string) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          // verify form control again without emitting valueChanges event
          control.updateValueAndValidity({ emitEvent: false });
        } else if (control instanceof FormGroup || control instanceof FormArray) {
          this.updateAllFormFieldsValidation(control);
        }
      });
    }
  }

  /**
   * Compared two data items
   *
   * @param valueA First data item to be compared
   * @param valueB Second data item to be compared
   */
  compareTwoValues(valueA: any, valueB: any): number {
    let comparatorResult = 0;
    if (valueA !== null && valueB !== null) {
      if (valueA === valueB) {
        return comparatorResult;
      }
      if (typeof valueA === 'number' || typeof valueA === 'boolean') {
        comparatorResult = valueA >= valueB ? 1 : -1;
      } else {
        valueA += BLANK;
        valueB += BLANK;
        if (this.containNorwegianLetter(valueA.toLowerCase()) || this.containNorwegianLetter(valueB.toLowerCase())) {
          comparatorResult = new Intl.Collator(NORWEGIAN_LOCALE).compare(valueA, valueB);
        } else {
          comparatorResult = valueA >= valueB ? 1 : -1;
        }
      }
    } else if (valueA !== null) {
      comparatorResult = 1;
    } else if (valueB !== null) {
      comparatorResult = -1;
    }
    return comparatorResult;
  }

  /**
   * Helper creating async validator with deboucing
   * @param checkingFn The function checking whether the value is valid or not
   * @param errorKey The error key should be returned when the value is invalid
   */
  createAsyncValidator(checkingFn: (value: string) => Observable<boolean>, errorKey: string): AsyncValidatorFn {
    return (control: FormControl): Observable<ValidationErrors> => {
      return timer(300).pipe(
        switchMap(() => {
          if (control.value === BLANK) {
            return of(null);
          }
          return checkingFn(control.value).pipe(
            map((isValid) => {
              setTimeout(() => control.parent.updateValueAndValidity()); // Workaround
              return !isValid ? { [errorKey]: true } : null;
            })
          );
        })
      );
    };
  }

  /**
   * Copy text to clipboard
   * @param val Text to copy
   */
  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /**
   * Remove an item in the array based on its index
   *
   * @param array target array
   * @param index index of the item to be removed
   */
  removeArrayItemOnIndex<T>(array: Array<T>, index: number): Array<T> {
    if (!array.length || index < 0 || index > array.length - 1) {
      return array;
    }
    return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
  }

  /**
   * Replace an item in the array based on its index
   *
   * @param array target array
   * @param index index of the item to be removed
   */
  replaceItemOnIndex<T>(array: Array<T>, index: number, newItem: T): Array<T> {
    if (!array.length || index < 0 || index > array.length - 1) {
      return array;
    }
    return [...array.slice(0, index), newItem, ...array.slice(index + 1, array.length)];
  }

  /**
   * Check whether the input object is a function
   *
   * @param functionToCheck
   */
  isFunction(functionToCheck: Function) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  /**
   * Check whether the input object is a string
   *
   * @param objectToCheck
   */
  isString(objectToCheck: any) {
    return Object.prototype.toString.call(objectToCheck) === '[object String]';
  }

  /**
   * Check whether the input object is a function, return the function call's result if it is
   *
   * @param functionObject Object that is supposed to be a function
   * @param args Array of arguments
   */
  checkFunctionCall(functionObject: Function, ...args: any[]) {
    if (!functionObject) {
      return null;
    }
    if (this.isFunction(functionObject)) {
      return functionObject(...args);
    }
    return null;
  }

  /**
   * Compare two objects to see if their properties are equal
   * with the exclusion of properties listed in excludedProperties parameter.
   * The values of each property in the two objects are deeply compared.
   * If there is any difference in the list of own properties of the two objects, the result will be false.
   * If any of the two object is falsy (even if they are both null or undefined), the result will be false.
   *
   * @param objectA the first object
   * @param objectB the second object
   * @param excludedProperties the list of excluded properties
   */
  compareTwoObjectExclude<T extends Object>(objectA: T, objectB: T, excludedProperties: Array<string> = []): boolean {
    if (!objectA || !objectB) {
      return false;
    }
    const aKeys = Object.keys(objectA).filter((key) => excludedProperties.indexOf(key) === -1);
    const bKeys = Object.keys(objectB).filter((key) => excludedProperties.indexOf(key) === -1);

    if (aKeys.length !== bKeys.length) {
      return false;
    }

    let equal = true;

    for (let i = 0; i < aKeys.length; i++) {
      const key = aKeys[i];
      if (bKeys.indexOf(key) === -1) {
        return false;
      }
      equal = equal && isEqual(objectA[key], objectB[key]);
      if (!equal) {
        return false;
      }
    }
    return equal;
  }

  /**
   * Return a shallow copy of the target object, excluding the specified properties
   *
   * @param object target object
   * @param excludedProperties the list of excluded properties
   */
  shallowCloneExclude<T extends Object>(object: T, excludedProperties: Array<string> = []): Partial<T> {
    if (!object || typeof object !== 'object') {
      return null;
    }
    const keys = Object.keys(object).filter((key) => excludedProperties.indexOf(key) === -1);
    const result = {};
    keys.forEach((key) => (result[key] = object[key]));
    return result;
  }

  /**
   * Fix the loss of carriage return \r character after editing
   * Such loss causes problem for comparison of string after editing
   * This does not deal with the situation where \r\n and \n both exist
   *
   * @param text target string
   */
  fixCarriageReturn(text: string): string {
    if (text.indexOf('\r\n') === -1 && text.indexOf('\n') !== -1) {
      return text.replace(/\n/g, '\r\n');
    }
    return text;
  }

  /**
   * Remove all carriage return \r characters
   * Remove \r character before set string value to text editable inputs
   *
   * @param text target string
   */
  removeCarriageReturn(text: string): string {
    return text.replace(/\r/g, BLANK);
  }

  /**
   * Return 00 if blank, 0x if input only has 1 digit x
   */
  patchZero(str: string): string {
    if (!str) {
      return ZERO + ZERO;
    }

    if (str.length === 1) {
      return ZERO + str;
    }

    return str;
  }

  /**
   * Create the HTML of a div element
   *
   * @param content div content
   * @param extraClass extra classes
   */
  createDivHTML(content: string, extraClass: string = BLANK): string {
    return `<div class="${extraClass}">${content}</div>`;
  }

  /**
   * Create a multi-row div element
   *
   * @param rows row content or div information
   */
  createMultiRowHTML(rows: Array<string | DivDeclaration>) {
    const parsedRows = rows.map((r) => {
      if (this.isString(r)) {
        return this.createDivHTML(r as string);
      } else {
        const { content, extraClass } = r as DivDeclaration;
        return this.createDivHTML(content, extraClass || BLANK);
      }
    });
    return this.createDivHTML(parsedRows.join(BLANK), 'd-flex flex-column');
  }

  /**
   * Check if input string contains Norwegian letters
   *
   * @param str input string
   */
  private containNorwegianLetter(str: string): boolean {
    return NORWEGIAN_LETTERS.some((nl) => str.includes(nl));
  }
}
